import { getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

const getLeadSourceId = (storeNumber?: string) => getCookie('leadSourceId') || (storeNumber ? '0' : '1');
const getUtmParams = (): Record<string, string> => {
	const utmSource = getCookie('utm_source') || 'brightway-com';
	const utmMedium = getCookie('utm_medium') || 'website';
	const utmCampaign = getCookie('utm_campaign') || undefined;
	const result: Record<string, string> = {
		utm_source: utmSource,
		utm_medium: utmMedium,
	};
	if (utmCampaign) {
		result.utm_campaign = utmCampaign;
	}
	return result;
};

const getInsuranceProduct = (leadInsuranceTypeId: string) => {
	const insuranceTypes = {
		'1': 'Home',
		'2': 'Flood',
		'3': 'Condo',
		'4': 'Renters',
		'5': 'Personal Articles',
		'6': 'Auto',
		'7': 'Motorcycle',
		'8': 'RV',
		'9': 'Boat',
		'10': 'Business',
		'11': 'Life',
		'12': 'Umbrella',
		'15': 'Pets',
		'9999': 'Other',
	};
	return insuranceTypes[leadInsuranceTypeId];
};

export const getFrameSrc = ({
	intakeFormUrl,
	storeNumber,
}: {
	intakeFormUrl: string;
	storeNumber?: string;
}) => {
	let pathBase = storeNumber
		? `${intakeFormUrl}/agencies/${storeNumber}`
		: intakeFormUrl;

	if (storeNumber === '0119') {
		pathBase = `${intakeFormUrl}/agencies/0090`;
	}

	const leadInsuranceTypeId = localStorage.getItem('leadInsuranceTypeId');
	const postalCode = localStorage.getItem('postalCode');
	const leadSourceId = getLeadSourceId(storeNumber);
	const utmParams = getUtmParams();

	const params = new URLSearchParams({
		leadSourceId,
		...(postalCode ? { postalCode } : {}),
		...(leadInsuranceTypeId
			? { insuranceProducts: getInsuranceProduct(leadInsuranceTypeId) }
			: {}),
		...utmParams,
		// TODO: This is a temporary solution for 0119 - revisit removing this site in April 2025
		...(storeNumber === '0119' ? { utm_campaign: '0119' } : {}),
	}).toString();

	return pathBase + '?' + params;
};

export const useFrameSrc = (IntakeFormUrl, storeNumber) => {
	const [src, setSrc] = useState<string | null>(null);
	useEffect(() => {
		setSrc(getFrameSrc({ intakeFormUrl: IntakeFormUrl, storeNumber }));
	});
	return src;
};
